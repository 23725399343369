const ONBOARDING_STORAGE_KEY = 'shown_onboarding'
const DENY_ROUTE_NAMES = [
  'cart',
  'qr-draftOrderAlias',
  'qr-timeout',
  'order-orderAlias',
  'address-orderId',
  'address-orderId-complete',
]

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()
  // fix: @see https://github.com/route06/ss-bonsai-catalog-frontend/issues/369
  const isDeny = route.name && DENY_ROUTE_NAMES.includes(route.name.toString())
  if (isDeny) {
    nuxtApp.$storage.set(ONBOARDING_STORAGE_KEY, true)
  }

  const shownOnboarding = nuxtApp.$storage.get(ONBOARDING_STORAGE_KEY)
  if (shownOnboarding) {
    return
  }
  nuxtApp.$store.commit('onboarding/openDialog')
  nuxtApp.$storage.set(ONBOARDING_STORAGE_KEY, true)
})
