import { Storage } from './lib'

const storage = new Storage(window.localStorage)

export default defineNuxtPlugin(() => {
  return {
    provide: {
      storage,
    },
  }
})
