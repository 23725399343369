import { default as indexbiprgBvj4eMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/about/index.vue?macro=true";
import { default as completeGrFPTQA7XlMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/complete.vue?macro=true";
import { default as indexPS6KEeIyg1Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/index.vue?macro=true";
import { default as index72UFMpUI0CMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/area/index.vue?macro=true";
import { default as _91id_93OLOt3LsVM7Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/[id].vue?macro=true";
import { default as indexsSRQGyGITRMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/index.vue?macro=true";
import { default as indexo0y39vP2zKMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/cart/index.vue?macro=true";
import { default as indext6UzV2QmlvMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/index.vue?macro=true";
import { default as _91orderAlias_93zNcvLk5reXMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/order/[orderAlias].vue?macro=true";
import { default as _91id_93JlSfqxzGGVMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/[id].vue?macro=true";
import { default as indexBimPglNFi0Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/index.vue?macro=true";
import { default as _91draftOrderAlias_93EsfWLHGjKLMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/[draftOrderAlias].vue?macro=true";
import { default as timeoutzqtyoiJM71Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/timeout.vue?macro=true";
import { default as index0GjoMCLfXGMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/terms/index.vue?macro=true";
import { default as indexUh4ONqSeZ8Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: indexbiprgBvj4eMeta?.name ?? "about",
    path: indexbiprgBvj4eMeta?.path ?? "/about",
    meta: indexbiprgBvj4eMeta || {},
    alias: indexbiprgBvj4eMeta?.alias || [],
    redirect: indexbiprgBvj4eMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: completeGrFPTQA7XlMeta?.name ?? "address-shipmentAlias-complete",
    path: completeGrFPTQA7XlMeta?.path ?? "/address/:shipmentAlias()/complete",
    meta: completeGrFPTQA7XlMeta || {},
    alias: completeGrFPTQA7XlMeta?.alias || [],
    redirect: completeGrFPTQA7XlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/complete.vue").then(m => m.default || m)
  },
  {
    name: indexPS6KEeIyg1Meta?.name ?? "address-shipmentAlias",
    path: indexPS6KEeIyg1Meta?.path ?? "/address/:shipmentAlias()",
    meta: indexPS6KEeIyg1Meta || {},
    alias: indexPS6KEeIyg1Meta?.alias || [],
    redirect: indexPS6KEeIyg1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/index.vue").then(m => m.default || m)
  },
  {
    name: index72UFMpUI0CMeta?.name ?? "area",
    path: index72UFMpUI0CMeta?.path ?? "/area",
    meta: index72UFMpUI0CMeta || {},
    alias: index72UFMpUI0CMeta?.alias || [],
    redirect: index72UFMpUI0CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/area/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OLOt3LsVM7Meta?.name ?? "brands-id",
    path: _91id_93OLOt3LsVM7Meta?.path ?? "/brands/:id()",
    meta: _91id_93OLOt3LsVM7Meta || {},
    alias: _91id_93OLOt3LsVM7Meta?.alias || [],
    redirect: _91id_93OLOt3LsVM7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsSRQGyGITRMeta?.name ?? "brands",
    path: indexsSRQGyGITRMeta?.path ?? "/brands",
    meta: indexsSRQGyGITRMeta || {},
    alias: indexsSRQGyGITRMeta?.alias || [],
    redirect: indexsSRQGyGITRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexo0y39vP2zKMeta?.name ?? "cart",
    path: indexo0y39vP2zKMeta?.path ?? "/cart",
    meta: indexo0y39vP2zKMeta || {},
    alias: indexo0y39vP2zKMeta?.alias || [],
    redirect: indexo0y39vP2zKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indext6UzV2QmlvMeta?.name ?? "index",
    path: indext6UzV2QmlvMeta?.path ?? "/",
    meta: indext6UzV2QmlvMeta || {},
    alias: indext6UzV2QmlvMeta?.alias || [],
    redirect: indext6UzV2QmlvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderAlias_93zNcvLk5reXMeta?.name ?? "order-orderAlias",
    path: _91orderAlias_93zNcvLk5reXMeta?.path ?? "/order/:orderAlias()",
    meta: _91orderAlias_93zNcvLk5reXMeta || {},
    alias: _91orderAlias_93zNcvLk5reXMeta?.alias || [],
    redirect: _91orderAlias_93zNcvLk5reXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/order/[orderAlias].vue").then(m => m.default || m)
  },
  {
    name: _91id_93JlSfqxzGGVMeta?.name ?? "product-tags-id",
    path: _91id_93JlSfqxzGGVMeta?.path ?? "/product-tags/:id()",
    meta: _91id_93JlSfqxzGGVMeta || {},
    alias: _91id_93JlSfqxzGGVMeta?.alias || [],
    redirect: _91id_93JlSfqxzGGVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBimPglNFi0Meta?.name ?? "product-tags",
    path: indexBimPglNFi0Meta?.path ?? "/product-tags",
    meta: indexBimPglNFi0Meta || {},
    alias: indexBimPglNFi0Meta?.alias || [],
    redirect: indexBimPglNFi0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/index.vue").then(m => m.default || m)
  },
  {
    name: _91draftOrderAlias_93EsfWLHGjKLMeta?.name ?? "qr-draftOrderAlias",
    path: _91draftOrderAlias_93EsfWLHGjKLMeta?.path ?? "/qr/:draftOrderAlias()",
    meta: _91draftOrderAlias_93EsfWLHGjKLMeta || {},
    alias: _91draftOrderAlias_93EsfWLHGjKLMeta?.alias || [],
    redirect: _91draftOrderAlias_93EsfWLHGjKLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/[draftOrderAlias].vue").then(m => m.default || m)
  },
  {
    name: timeoutzqtyoiJM71Meta?.name ?? "qr-timeout",
    path: timeoutzqtyoiJM71Meta?.path ?? "/qr/timeout",
    meta: timeoutzqtyoiJM71Meta || {},
    alias: timeoutzqtyoiJM71Meta?.alias || [],
    redirect: timeoutzqtyoiJM71Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/timeout.vue").then(m => m.default || m)
  },
  {
    name: index0GjoMCLfXGMeta?.name ?? "terms",
    path: index0GjoMCLfXGMeta?.path ?? "/terms",
    meta: index0GjoMCLfXGMeta || {},
    alias: index0GjoMCLfXGMeta?.alias || [],
    redirect: index0GjoMCLfXGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexUh4ONqSeZ8Meta?.name ?? "wishlist",
    path: indexUh4ONqSeZ8Meta?.path ?? "/wishlist",
    meta: indexUh4ONqSeZ8Meta || {},
    alias: indexUh4ONqSeZ8Meta?.alias || [],
    redirect: indexUh4ONqSeZ8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/wishlist/index.vue").then(m => m.default || m)
  }
]