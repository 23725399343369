import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import 'vuetify/styles'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    // https://vuetifyjs.com/en/features/theme/#custom-theme-colors
    theme: {
      defaultTheme: 'light',
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#333',
            secondary: '#666',
            accent: '#999',
            info: '#666',
            warning: '#dd807f',
            error: '#dd807f',
            success: '#333',
          },
        },
      },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})
