import { CartFilter } from '~/plugins/filter/cart'
import { CommonFilter } from '~/plugins/filter/common'
import { ProductFilter } from '~/plugins/filter/product'

export type Filter = {
  product: ProductFilter
  cart: CartFilter
  common: CommonFilter
}

const filter: Filter = {
  product: new ProductFilter(),
  cart: new CartFilter(),
  common: new CommonFilter(),
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      filter,
    },
  }
})
