import {
  areaVuexStore,
  cartVuexStore,
  indexVuexStore,
  onboardingVuexStore,
  productVuexStore,
  themeVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      area: {
        ...areaVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      cart: {
        ...cartVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      onboarding: {
        ...onboardingVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      product: {
        ...productVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      theme: {
        ...themeVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore