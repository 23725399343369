export type AreaPlace = 'a' | 'b' | 'c' | 'd'

export class Area {
  constructor(
    readonly id: number,
    readonly imageSrc: URL,
    readonly description: string,
    readonly place: AreaPlace
  ) {}

  public getTitle(): string {
    switch (this.place) {
      case 'a':
        return 'BASE A'
      case 'b':
        return 'BASE B'
      case 'c':
        return 'BASE C'
      case 'd':
        return 'BASE D'
      default:
        return ''
    }
  }

  public getImage(): string {
    return this.imageSrc.toString()
  }
}
