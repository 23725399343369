import { Cart } from '~/models/Cart'

export class CartFilter {
  getDisplayGrandTotal(cart: Cart): string {
    return `¥${cart.grandTotal.separatePrice()}`
  }

  getDisplaySubtotal(cart: Cart): string {
    return `¥${cart.subtotal.separatePrice()}`
  }

  getDisplayShippingCost(cart: Cart): string {
    if (!cart.shipping) return ''
    return `¥${cart.shipping.charge.separatePrice()}`
  }

  getDisplayWrappingCost(cart: Cart): string {
    if (!cart.wrapping) return ''
    if (!cart.wrapping.enable) return ''
    return `¥${cart.wrapping.fee.separatePrice()}`
  }
}
