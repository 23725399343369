import { AreaPlace } from '~/models/Area'
import { Product, ProductImage, Tenant, Variant } from '~/models/Product'

type ProductImageResponse = {
  id: string
  src: string
}

type ProductVariantResponse = {
  id: string
  sku: string
  title: string
  compareAtPrice?: number
  price: number
  stockInShop: number
  imageId: string
  options?: { name: string; value: string }[]
}

type TenantResponse = {
  id: number
  name: string
  nameKana?: string
  logoSrc?: string
  description: string
}

/**
 * @see ss-bonsai-api: src/products/product.entity.ts
 */
export type ProductResponse = {
  id: number
  shopifyProductId: string
  spaceId: number
  title: string
  description: string
  descriptionHtml: string
  updatedAt: string
  images: ProductImageResponse[]
  category: string
  startedAt: string
  endedAt: string
  variants: ProductVariantResponse[]
  tenant: TenantResponse
  stockInShop: number
  areas: { id: number; place: AreaPlace }[]
  videoSrc?: string
  tags?: { id: number; name: string }[]
}

export type ProductsResponse = {
  count: number
  products: ProductResponse[]
}

const toURL = (urlStr: string | undefined): URL | undefined => {
  if (!urlStr) return
  try {
    return new URL(urlStr)
  } catch (e) {}
}

export function toProductDetail(res: ProductResponse): Product {
  const toProductImages = (res: ProductImageResponse[]): ProductImage[] => {
    return res.map((it) => ({ id: it.id, url: it.src }))
  }

  const toProductVariants = (
    res: ProductVariantResponse[],
    resImages: ProductImageResponse[]
  ): Variant[] => {
    return res.map((it) => {
      const image = resImages.find((resImage) => resImage.id === it.imageId)
      let productImage: ProductImage | undefined
      if (image) {
        productImage = { id: it.imageId, url: image.src }
      }

      const isSale = it.compareAtPrice
        ? it.compareAtPrice - it.price > 0
        : false

      return {
        id: it.id,
        sku: it.sku,
        title: it.title,
        compareAtPrice: it.compareAtPrice,
        price: it.price,
        isSale,
        stockInShop: it.stockInShop,
        // 将来直送配送が入った場合は +0 を + it.stockInDirectに変更する
        totalStock: it.stockInShop + 0,
        images: productImage,
        options: it.options,
      }
    })
  }

  return {
    id: res.id,
    shopifyProductId: res.shopifyProductId,
    images: toProductImages(res.images),
    title: res.title,
    descriptionHtml: res.descriptionHtml,
    variants: toProductVariants(res.variants, res.images),
    tenant: {
      ...res.tenant,
      logoSrc: toURL(res.tenant.logoSrc),
    },
    videoSrc: toURL(res.videoSrc),
    tags: res.tags,
    stockInShop: res.stockInShop,
    areas: res.areas,
  }
}
