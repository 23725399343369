import { Area, AreaPlace } from '~/models/Area'
import { customFetch } from './repository/customFetch'

type AreaResponse = {
  readonly id: number
  readonly description: string
  readonly imageSrc: string
  readonly place: AreaPlace
}[]

class AreaRepository {
  readonly customFetch = customFetch

  public async find(): Promise<Area[]> {
    const { data, error } = await this.customFetch<AreaResponse>(
      `/catalog/areas`
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('areas not found.')
    }

    const areas = data.value.map((it) => {
      const url = new URL(it.imageSrc)
      return new Area(it.id, url, it.description, it.place)
    })

    return areas
  }
}

export function useAreaRepository() {
  return new AreaRepository()
}
