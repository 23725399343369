import { Product, VariantId } from '~/models/Product'

export const RECEIVE = {
  STORE: 'store',
  SHIP: 'ship',
} as const
export type Receive = (typeof RECEIVE)[keyof typeof RECEIVE]

export type Shipping = {
  readonly charge: number
}

export type Wrapping = {
  readonly enable: boolean
  readonly fee: number
}

export type CartProduct = Product & {
  price: number
  qty: number
  totalPrice: number
  receive: Receive
  selectVariantId: VariantId
}

export type Cart = {
  products: CartProduct[]
  shipping?: Shipping
  wrapping?: Wrapping
  grandTotal: number
  subtotal: number
}

export type CartConfirm = {
  products: CartProduct[]
  grandTotal: number
  shipping?: Shipping
  wrapping?: Wrapping
  subtotal: number
  urlForQrCode: string
}

export type CartCount = {
  count: number
}
export type PurchasableStatusCode =
  // 購入ができない商品のため、カートから削除
  | 'delete'
  // 在庫がないため、カートから削除
  | 'delete_out_of_stock'
  // 購入在庫数が変更された
  | 'change_qty'
  // 価格が更新された
  | 'change_price'

export type WarningProduct = {
  variantId: string
  code: PurchasableStatusCode
  name: string
}
