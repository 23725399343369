import { Product, Variant, VariantOption } from '~/models/Product'
import { genThumbImageUrl, genMainImageUrl } from '~/shared/image_url'

type DisplayOption = {
  name: string
  options: { label: string; disabled: boolean }[]
}
export class ProductFilter {
  mainImageUrl(src?: string): string {
    return src
      ? genMainImageUrl(src)
      : 'https://prod-common-image.s3.ap-northeast-1.amazonaws.com/public/catalog/cbs-logo.jpg'
  }

  thumbImageUrl(src: string): string {
    return src
      ? genThumbImageUrl(src)
      : 'https://prod-common-image.s3.ap-northeast-1.amazonaws.com/public/catalog/cbs-logo.jpg'
  }

  getDisplayPrice(
    selectVariant: Variant | undefined,
    product: Product
  ): string {
    if (!selectVariant) {
      return `¥${product.variants[0].price.separatePrice()}`
    }
    return `¥${selectVariant.price.separatePrice()}`
  }

  getCartBtnText(product: Product) {
    return product.stockInShop > 0 ? 'お買い物バッグに入れる' : 'SOLD OUT'
  }

  getVariantCartBtnText(selectVariant: Variant | undefined): string {
    if (!selectVariant || selectVariant.totalStock) {
      return 'お買い物バッグに入れる'
    }

    return 'SOLD OUT'
  }

  getDisplayOptions(product: Product): DisplayOption[] {
    if (!product) {
      return []
    }

    const variants = product.variants
    const options = variants.flatMap((variant) => {
      return variant.options ?? []
    })
    const tempOptions: Record<VariantOption['name'], VariantOption['value'][]> =
      {}

    options.forEach((option) => {
      if (tempOptions[option.name]) {
        tempOptions[option.name].push(option.value)
      } else {
        tempOptions[option.name] = [option.value]
      }
    })

    return Object.entries(tempOptions).map(([key, value]) => ({
      name: key,
      options: [...new Set(value)].map((value) => {
        const disabled = variants
          .filter((variant) => {
            return variant.options?.some(
              (option) => option.name === key && option.value === value
            )
          })
          .every((variant) => {
            return variant.totalStock === 0
          })

        return {
          label: value,
          disabled,
        }
      }),
    }))
  }
}
