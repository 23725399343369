// @see https://day.js.org/docs/en/installation/typescript#locale-and-plugin-import

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
/* eslint-disable import/no-named-as-default-member */

export const JST_TIMEZONE = 'Asia/Tokyo'

dayjs.locale('ja')

export { dayjs }
