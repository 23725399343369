type LocalStorage = typeof localStorage

export class Storage {
  _storage: LocalStorage

  constructor(storage: LocalStorage) {
    this._storage = storage
  }

  get<T>(key: string): T | null {
    const item = this._storage.getItem(key)
    if (item) {
      return JSON.parse(item) as T
    }
    return null
  }

  set<T>(key: string, value: T): void {
    this._storage.setItem(key, JSON.stringify(value))
  }

  remove(key: string): void {
    this._storage.removeItem(key)
  }
}
