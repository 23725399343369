type ImagePraram = { src: string; size: number }

export const genImageUrl = ({ src, size = 256 }: ImagePraram): string => {
  const splitedUrl = src.split('.')
  const resizedUrl = [
    splitedUrl.slice(0, -1).join('.'),
    `_${size}x${size}`,
    '.',
    splitedUrl[splitedUrl.length - 1],
  ].join('')

  return resizedUrl
}

export const genThumbImageUrl = (src: string): string => {
  return genImageUrl({ src, size: 256 })
}

export const genMainImageUrl = (src: string): string => {
  return genImageUrl({ src, size: 768 })
}
