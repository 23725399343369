const INSTAGRAM_EMBED_SCRIPT = 'https://www.instagram.com/embed.js'
const TIKTOK_EMBED_SCRIPT = 'https://www.tiktok.com/embed.js'

class EmbedScript {
  id: string
  src: string
  waitForPageLoad: boolean
  isLoading = false

  constructor(id: string, src: string, waitForPageLoad = true) {
    this.id = id
    this.src = src
    this.waitForPageLoad = waitForPageLoad
  }

  load() {
    if (this.isLoading) return

    this.isLoading = true
    this._removeScript()

    if (!this.waitForPageLoad || document.readyState === 'complete') {
      this._loadScript()
    } else {
      window.addEventListener('load', () => this._loadScript())
    }
  }

  _removeScript() {
    document.getElementById(this.id)?.remove()
  }

  _loadScript() {
    const script = document.createElement('script')
    script.src = this.src
    script.defer = true
    script.id = this.id
    script.onload = () => (this.isLoading = false)
    document.getElementsByTagName('head')[0].appendChild(script)
  }
}

export default defineNuxtPlugin(() => {
  const instagramLoader = new EmbedScript(
    'instagram-embed-script',
    INSTAGRAM_EMBED_SCRIPT
  )
  const tiktokLoader = new EmbedScript(
    'tiktok-embed-script',
    TIKTOK_EMBED_SCRIPT
  )
  return {
    provide: {
      instagramLoader: {
        load() {
          delete (window as any).instgrm
          instagramLoader.load()
        },
      },
      tiktokLoader: {
        load() {
          tiktokLoader.load()
        },
      },
    },
  }
})
