import {
  Cart,
  CartConfirm,
  CartProduct,
  Receive,
  RECEIVE,
  CartCount,
  WarningProduct,
} from '~/models/Cart'
import { ShopifyProductId, VariantId } from '~/models/Product'
import { customFetch } from './repository/customFetch'
import {
  ProductResponse,
  toProductDetail,
} from './repository/product/product.res'

type AddToCartResponse = {
  readonly status: boolean
}

type CartConfirmResponse =
  | {
      urlForQrCode: string
      grandTotal: number
      subtotal: number
      shippingCharge?: number
      wrappingFee?: number
      products: (ProductResponse & {
        price: number
        qty: number
        totalPrice: number
        receive: string
        selectVariantId: string
      })[]
    }
  | { errorCode: 'redirect_order'; orderAlias: string }
  | { errorCode: 'redirect_draft_order'; redirectDraftOrderAlias: string }
  | { errorCode: 'timeout' }

type DraftOrderStatusDto = {
  status: 'active' | 'cancel' | 'ordered'
  orderAlias?: string
}

type CartResponse = {
  grandTotal: number
  subtotal: number
  shippingCharge?: number
  wrapping: {
    enable: boolean
    fee: number
  }
  products: (ProductResponse & {
    price: number
    qty: number
    totalPrice: number
    receive: string
    selectVariantId: string
  })[]
}

type FetchCartResponse = CartResponse & {
  warning: { products: WarningProduct[] }
}

export type IssueQrResponse = CartResponse & {
  warning: {
    qr?:
      | { code: 'already_issued_code'; draftOrderAlias: string }
      | { code: 'no_products' }
    products?: WarningProduct[]
    coupon?: { code: string }
  }
}

const toCart = (cartResponse: CartResponse): Cart => {
  const products = cartResponse.products.map((it): CartProduct => {
    const product = toProductDetail(it)
    return {
      ...product,
      price: it.price,
      totalPrice: it.totalPrice,
      selectVariantId: it.selectVariantId,
      qty: it.qty,
      receive: it.receive === RECEIVE.STORE ? RECEIVE.STORE : RECEIVE.SHIP,
    }
  })

  return {
    products,
    shipping:
      cartResponse.shippingCharge != null
        ? { charge: cartResponse.shippingCharge }
        : undefined,
    wrapping: cartResponse.wrapping,
    grandTotal: cartResponse.grandTotal,
    subtotal: cartResponse.subtotal,
  }
}

class CartRepository {
  private customFetch = customFetch

  public async getCartProductsCount(): Promise<CartCount> {
    const { data, error } = await this.customFetch<CartCount>(
      `catalog/cart/products/count`
    )

    if (error.value) {
      throw new Error(error.value.message)
    }
    if (!data.value) {
      throw new Error('count not found.')
    }

    return data.value
  }

  public async addToCart(param: {
    shopifyProductId: ShopifyProductId
    variantId: VariantId
    addQty: number
  }): Promise<boolean> {
    // Maybe AddToCartResponse is wrong..
    const { data, error } = await this.customFetch<AddToCartResponse>(
      `/catalog/cart/product`,
      {
        method: 'POST',
        body: {
          productId: param.shopifyProductId,
          variantId: param.variantId,
          addQty: param.addQty,
        },
      }
    )

    if (error.value) {
      throw error.value
    }

    return true
  }

  public async updateQty(param: {
    shopifyProductId: ShopifyProductId
    variantId: VariantId
    qty: number
  }): Promise<Cart> {
    const { data, error } = await this.customFetch<FetchCartResponse>(
      `/catalog/cart/product-qty`,
      {
        method: 'PUT',
        body: {
          productId: param.shopifyProductId,
          variantId: param.variantId,
          qty: param.qty,
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('product qty not found.')
    }

    return toCart(data.value)
  }

  public async updateReceive(param: {
    shopifyProductId: ShopifyProductId
    variantId: VariantId
    receive: Receive
  }): Promise<Cart> {
    const { data, error } = await this.customFetch<FetchCartResponse>(
      `/catalog/cart/product-receive`,
      {
        method: 'PUT',
        body: {
          productId: param.shopifyProductId,
          variantId: param.variantId,
          receive: param.receive,
        },
      }
    )
    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('product receive not found.')
    }

    return toCart(data.value)
  }

  public async updateWrapping(params: { wrapping: boolean }): Promise<Cart> {
    const { data, error } = await this.customFetch<FetchCartResponse>(
      `/catalog/cart/wrapping`,
      {
        method: 'PUT',
        body: params,
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('cart not found.')
    }

    return toCart(data.value)
  }

  public async fetchCart(): Promise<{
    cart: Cart
    warning: { products: WarningProduct[] }
  }> {
    const { data, error } = await this.customFetch<FetchCartResponse>(
      `/catalog/cart`,
      {
        method: 'POST',
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('cart not found.')
    }

    return {
      cart: toCart(data.value),
      warning: data.value.warning,
    }
  }

  public async issueQr(): Promise<{
    cart: Cart
    warning: IssueQrResponse['warning']
  }> {
    const { data, error } = await this.customFetch<IssueQrResponse>(
      `/catalog/cart/qr`,
      { method: 'POST' }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('Something went wrong.')
    }

    return {
      cart: toCart(data.value),
      warning: data.value.warning,
    }
  }

  public async getCartConfirm(
    draftOrderAlias: string
  ): Promise<
    | CartConfirm
    | { errorCode: 'redirect_order'; orderAlias: string }
    | { errorCode: 'redirect_draft_order'; redirectDraftOrderAlias: string }
    | { errorCode: 'timeout' }
  > {
    const { data, error } = await this.customFetch<CartConfirmResponse>(
      `catalog/cart/qr/${draftOrderAlias}`
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('cart not found.')
    }

    const body = data.value
    if ('errorCode' in body) {
      return body
    }

    const products = body.products.map((it: any): CartProduct => {
      const product = toProductDetail(it)
      return {
        ...product,
        price: it.price,
        totalPrice: it.totalPrice,
        selectVariantId: it.selectVariantId,
        qty: it.qty,
        receive: it.receive === RECEIVE.STORE ? RECEIVE.STORE : RECEIVE.SHIP,
      }
    })

    return {
      products,
      shipping:
        body.shippingCharge != null
          ? { charge: body.shippingCharge }
          : undefined,
      wrapping:
        body.wrappingFee != null
          ? { enable: body.wrappingFee != null, fee: body.wrappingFee }
          : undefined,
      subtotal: body.subtotal,
      urlForQrCode: body.urlForQrCode,
      grandTotal: body.grandTotal,
    }
  }

  public async getDraftOrderStatus(
    draftOrderAlias: string
  ): Promise<DraftOrderStatusDto> {
    const { data, error } = await this.customFetch<DraftOrderStatusDto>(
      `catalog/cart/qr/${draftOrderAlias}/status`
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('draft order not found.')
    }

    return data.value
  }
}

export function useCartRepository() {
  return new CartRepository()
}
