import { GetterTree, ActionTree, MutationTree, ActionContext } from 'vuex'
import {
  Cart,
  CartProduct,
  Receive,
  CartCount,
  WarningProduct,
} from '~/models/Cart'
import { ShopifyProductId, VariantId } from '~/models/Product'
import { RootState } from '~/store'

const cartRepository = useCartRepository()

type StateType = {
  cart?: Cart
  warning: { products: WarningProduct[] }
  count: CartCount['count']
  countCached: boolean
  isAgreeTerms: boolean
}
export const state = (): StateType => ({
  cart: undefined,
  warning: { products: [] },
  count: 0,
  countCached: false,
  isAgreeTerms: false,
})
export type CartState = ReturnType<typeof state>

export const mutations: MutationTree<CartState> = {
  update(state: CartState, payload: { cart: Cart }) {
    state.cart = payload.cart
  },
  updateWarning(
    state: CartState,
    payload: { warning: { products: WarningProduct[] } }
  ) {
    state.warning = payload.warning
  },
  updateQty(
    state: CartState,
    payload: { selectVariantId: VariantId; qty: number }
  ) {
    state.cart?.products.forEach((it) => {
      if (it.selectVariantId === payload.selectVariantId) {
        it.qty = payload.qty
      }
    })
  },
  updateReceive(
    state: CartState,
    payload: {
      selectVariantId: VariantId
      receive: Receive
    }
  ) {
    state.cart?.products.forEach((it) => {
      if (it.selectVariantId === payload.selectVariantId) {
        it.receive = payload.receive
      }
    })
  },
  updateIsAgreeTerms(state: CartState, isAgree: boolean) {
    state.isAgreeTerms = isAgree
  },
  updateCartCount(state: CartState, count: CartCount['count']) {
    state.count = count
  },
  setCartCountCached(state, { cached }) {
    state.countCached = cached
  },
}

export const getters: GetterTree<CartState, RootState> = {
  cart: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): Cart | undefined => {
    return state.cart
  },
  warning: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): { products: WarningProduct[] } => {
    return state.warning
  },
  shopifyProductIds: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): CartProduct['shopifyProductId'][] => {
    return (
      state.cart?.products.map((it) => {
        return it.shopifyProductId
      }) ?? []
    )
  },
  productPriceList: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): CartProduct['price'][] => {
    return (
      state.cart?.products.map((it) => {
        return it.price
      }) ?? []
    )
  },
  receiveList: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): CartProduct['receive'][] => {
    return (
      state.cart?.products.map((it) => {
        return it.receive
      }) ?? []
    )
  },
  isAgreeTerms: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): boolean => {
    return state.isAgreeTerms
  },
  hasProducts: (
    state: CartState,
    getters: any,
    rootState: RootState
  ): boolean => {
    return state.count > 0
  },
}

export const actions: ActionTree<CartState, RootState> = {
  async fetchCart(context: ActionContext<CartState, RootState>) {
    const res = await cartRepository.fetchCart().catch(() => {
      context.state.cart = undefined
    })

    if (res) {
      context.commit('update', { cart: res.cart })
      context.commit('updateWarning', { warning: res.warning })

      const productCount = res.cart.products.length
      context.commit('updateCartCount', productCount)
    }
  },

  async updateQty(
    context: ActionContext<CartState, RootState>,
    payload: {
      shopifyProductId: ShopifyProductId
      variantId: VariantId
      qty: number
    }
  ) {
    const cart = await cartRepository.updateQty(payload)
    context.commit('update', { cart })
  },

  async updateReceive(
    context: ActionContext<CartState, RootState>,
    payload: {
      shopifyProductId: ShopifyProductId
      variantId: VariantId
      receive: Receive
    }
  ) {
    const cart = await cartRepository.updateReceive(payload)
    context.commit('update', { cart })
  },

  async updateWrapping(
    context: ActionContext<CartState, RootState>,
    payload: { wrapping: boolean }
  ) {
    const cart = await cartRepository.updateWrapping({
      wrapping: payload.wrapping,
    })
    context.commit('update', { cart })
  },

  updateIsAgreeTerms(
    context: ActionContext<CartState, RootState>,
    isAgree: boolean
  ) {
    context.commit('updateIsAgreeTerms', isAgree)
  },

  async getCartCount(context: ActionContext<CartState, RootState>) {
    const count = await cartRepository
      .getCartProductsCount()
      .then(({ count }) => count)

    context.commit('updateCartCount', count)
    context.commit('setCartCountCached', { cached: true })
  },
}
