import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

type StateType = {
  showDialog: boolean
}
export const state = (): StateType => ({
  showDialog: false,
})

export const mutations: MutationTree<StateType> = {
  openDialog(state: StateType) {
    state.showDialog = true
  },
  closeDialog(state: StateType) {
    state.showDialog = false
  },
}

export const getters: GetterTree<StateType, RootState> = {
  showDialog: (state: StateType): boolean => {
    return state.showDialog
  },
}
