import * as Sentry from '@sentry/vue'

const ignoredMessageList: string[] = [
  'Unable to preload CSS',
  'ResizeObserver loop completed',
  'Importing a module script failed',
]

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  Sentry.init({
    enabled: runtimeConfig.public.SENTRY_ENABLED,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: runtimeConfig.public.ENV !== 'production',
    dsn: runtimeConfig.public.SENTRY_DSN,
    release: runtimeConfig.public.SENTRY_RELEASE,
    environment: runtimeConfig.public.SENTRY_ENVIRONMENT,
    trackComponents: false,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0,
    // https://github.com/route06/ss-bonsai/issues/3626#issuecomment-1510504907
    beforeSendTransaction(event) {
      if (event.transaction === '/tracking/unique-id') {
        return null
      }
      return event
    },
    ignoreErrors: ignoredMessageList,
  })

  return {
    provide: {
      sentrySetUser: Sentry.setUser,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
