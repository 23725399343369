import { isBonsaiError } from '~/composables/repository/customFetch'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    const { $sentryCaptureException } = useNuxtApp()

    const data = (error as any).data
    if (!isBonsaiError(data)) {
      // API側でハンドリングされていないエラー
      $sentryCaptureException(error)
    }
  }
})
