import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { Area, AreaPlace } from '~/models/Area'
import { SelectFilterOption } from '~/models/FilterOption'
import { ShopifyProductId, TenantId } from '~/models/Product'
import { RootState } from '~/store'

const getTargetArea = (areas: Area[], place: AreaPlace) => {
  return areas.find((it) => it.place === place)
}
const areaRepository = useAreaRepository()

type AreaType = {
  areas: {
    value: Area
    productOrder: ShopifyProductId[]
    totalProductCount?: number
    firstFetchAt?: Date
    selectFilterOption?: SelectFilterOption
  }[]
}
export const state = (): AreaType => ({
  areas: [],
})
export type AreaState = ReturnType<typeof state>

export const getters: GetterTree<AreaState, RootState> = {
  areas: (state: AreaState, getters: any, rootState: RootState): Area[] => {
    return state.areas.map((it) => it.value)
  },
  areaById: (
    state: AreaState,
    getters: any,
    rootState: RootState
  ): ((areaId: number) => Area | undefined) => {
    return (areaId: number) => {
      return state.areas.find((it) => it.value.id === areaId)?.value
    }
  },
  productOrderById: (
    state: AreaState,
    getters: any,
    rootState: RootState
  ): ((areaId: number) => ShopifyProductId[]) => {
    return (areaId: number) => {
      return (
        state.areas.find((it) => it.value.id === areaId)?.productOrder ?? []
      )
    }
  },
  selectFilterOptionById: (
    state: AreaState,
    getters: any,
    rootState: RootState
  ): ((areaId: number) => SelectFilterOption | undefined) => {
    return (areaId: number) => {
      return state.areas.find((it) => it.value.id === areaId)
        ?.selectFilterOption
    }
  },
  totalProductCountById: (
    state: AreaState,
    getters: any,
    rootState: RootState
  ): ((areaId: number) => number | undefined) => {
    return (areaId: number) => {
      return state.areas.find((it) => it.value.id === areaId)?.totalProductCount
    }
  },
  firstFetchAtById: (
    state: AreaState,
    getters: any,
    rootState: RootState
  ): ((areaId: number) => Date | undefined) => {
    return (areaId: number) => {
      return state.areas.find((it) => it.value.id === areaId)?.firstFetchAt
    }
  },
  getArea: (state: AreaState, getters: any) => {
    return (place: AreaPlace) => {
      return getTargetArea(getters.areas, place)
    }
  },
  areaA: (state, getters: any) => {
    return getters.getArea('a')
  },
  areaB: (state, getters: any) => {
    return getters.getArea('b')
  },
  areaC: (state, getters: any) => {
    return getters.getArea('c')
  },
  areaD: (state, getters: any) => {
    return getters.getArea('d')
  },
}

interface UpdateActionPayload {
  areas: Area[]
}
export const mutations: MutationTree<AreaState> = {
  update(state: AreaState, payload: UpdateActionPayload) {
    state.areas = payload.areas.map((it) => ({
      value: it,
      productOrder: [],
      totalProductCount: undefined,
      firstFetchAt: undefined,
    }))
  },
  addProductOrder(
    state: AreaState,
    payload: {
      areaId: number
      totalProductCount: number
      productOrder: ShopifyProductId[]
      tenantIds: TenantId[]
    }
  ) {
    const area = state.areas.find((it) => it.value.id === payload.areaId)
    if (area) {
      const isFirstAdd = area.productOrder.length === 0
      if (isFirstAdd) area.firstFetchAt = new Date()
      area.productOrder.push(...payload.productOrder)
      area.totalProductCount = payload.totalProductCount
      area.selectFilterOption = { tenantIds: payload.tenantIds }
    }
  },
  clearProductOrder(state: AreaState, payload: { areaId: number }) {
    const area = state.areas.find((it) => it.value.id === payload.areaId)
    if (area) {
      area.productOrder = []
      area.totalProductCount = undefined
      area.selectFilterOption = undefined
    }
  },
}

export const actions: ActionTree<AreaState, RootState> = {
  async fetch(context: ActionContext<AreaState, RootState>) {
    // TODO キャッシュ有効期間の実装を追加する
    if (context.state.areas.length) {
      return
    }

    const areas = await areaRepository.find()
    context.commit('update', { areas })
  },
}
