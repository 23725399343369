import { dayjs } from '~/shared/date'

export class CommonFilter {
  displayPrice(num: number): string {
    return `¥${num.separatePrice()}`
  }

  formatDate(date: Date, format: string): string {
    return dayjs(date).format(format)
  }
}
